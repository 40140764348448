<!-- 会员月卡详情 -->

<template>
	<div class="month-card-dialog">
		<w-dialog ref="dialogRef" title="详情" width="62%" top="10vh" :hideFooter="true">
			<w-title :title_name="'基本信息'"></w-title>
			<el-row :gutter="20" class="s-p-t">
				<el-col :span="8">
					<div class="flex">
						<div class="info-title">ID</div>
						<div class="info-content">{{ infoDatas.om_mm_id }}</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="flex">
						<div class="info-title">会员手机</div>
						<div class="info-content">{{ infoDatas.m_mobile }}</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="flex">
						<div class="info-title">会员有效期</div>
						<div class="info-content">{{ infoDatas.useRange }}</div>
					</div>
				</el-col>
			</el-row>

			<div class="flex align-center cycle-time">
				<div>周期：</div>
				<el-select v-model="cycleTime" placeholder="请选择周期" @change="cycleTimeChange">
					<el-option :label="item.useRange" :value="item.omc_id" v-for="item in cycleOption"
						:key="item.omc_id">
					</el-option>
				</el-select>
			</div>

			<div class="cycle">
				<div class="flex-btw cycle-info">
					<div class="cycle-content discount">
						<div class="cycle-title">会员折扣</div>
						<div class="cycle-text" v-if="cycleDatas.omc_service_charge_discount">
							{{ Math.round(cycleDatas.omc_service_charge_discount) }}折
						</div>
						<div class="cycle-text" v-else>无</div>
					</div>
					<div class="cycle-content integral">
						<div class="cycle-title">积分</div>
						<div class="cycle-text" v-if="cycleDatas.omc_integral_multiple">
							{{ cycleDatas.omc_integral_multiple }}倍
						</div>
						<div class="cycle-text" v-else>无</div>
					</div>
					<div class="cycle-content coupon">
						<div class="cycle-title">优惠券</div>
						<div class="coupon-content">
							<div class="s-m-t" v-if="cycleDatas.coupon.length == 0">暂无</div>
							<div v-else class="cycle-text" v-for="(item, i) in cycleDatas.coupon" :key="i">
								{{ item.omcc_ct_name }}
								-{{item.omcc_c_title}}
								-{{item.omcc_num}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</w-dialog>
	</div>
</template>

<script>
	import { ref } from "vue";
	import { Order, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";

	export default {
		emits: ["submit"],
		setup(props, { emit }) {
			const dialogRef = ref(null);  // 弹框对象
			const currentRow = ref('');  // 当前行数据
			const infoDatas = ref('');  // 详情数据
			const cycleTime = ref([]);  // 周期
			const cycleDatas = ref({ coupon: [], });  // 周期数据
			const cycleOption = ref([]);  // 周期选项
			/**
			 * 
			 * 打开对话框
			 * 
			 * **/
			const openDialog = (row) => {
				currentRow.value = row;
				cycleTime.value = '';
				cycleDatas.value = { coupon: [], };
				getMonthlyCardOrderInfo();
			}
			/**
			 * 
			 * 关闭对话框
			 * 
			 * **/
			const closeDialog = () => {
				dialogRef.value.close();
			}
			/**
			 * 
			 * 打开对话框加载状态
			 * 
			 * **/
			const closeDialogLoading = () => {
				dialogRef.value.isLoading = false;
			}
			/**
			 * 
			 * 获取详情数据
			 * 
			 * **/
			const getMonthlyCardOrderInfo = () => {
				Order.getMonthlyCardOrderInfo({ om_id: currentRow.value.om_id }).then((res) => {
					if (res.Code === 200) {
						infoDatas.value = res.Data.data;
						cycleOption.value = res.Data.cycle;
						if (cycleOption.value.length > 0) {
							cycleTime.value = cycleOption.value[0].omc_id;
							cycleDatas.value = cycleOption.value[0];
						}
						dialogRef.value.show();
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
			/**
			 * 
			 * 选择周期-显示对应数据
			 * 
			 * **/
			const cycleTimeChange = () => {
				cycleOption.value.forEach((item) => {
					if (item.omc_id == cycleTime.value) {
						cycleDatas.value = item;
					}
				})
			}
			return {
				openDialog,
				dialogRef,
				closeDialog,
				closeDialogLoading,
				currentRow,
				infoDatas,
				getMonthlyCardOrderInfo,
				cycleTime,
				cycleDatas,
				cycleTimeChange,
				cycleOption,
			};
		},
	};
</script>

<style lang="scss">
	.month-card-dialog {
		.info-title {
			color: #92979E;
			text-align: left;
			width: 80px;
		}

		.info-content {
			color: #262C30;
		}

		.cycle-time {
			margin-top: 25px;
		}

		.cycle {
			max-height: 500px;
			min-height: 300px;
			overflow: auto;
			margin-top: 10px;
		}

		.cycle-info {
			margin-top: 20px;
			margin-bottom: 10px;
		}

		.el-row {
			border: none;
		}

		.cycle-content {
			width: 360px;
			height: 120px;
			padding: 15px;
		}

		.cycle-title {
			color: #262C30;
			font-size: 18px;
			font-weight: bold;
		}

		.discount {
			background-image: url(~@/assets/img/discount.png);
			background-size: 100% 100%;
		}

		.integral {
			background-image: url(~@/assets/img/integral.png);
			background-size: 100% 100%;
		}

		.coupon {
			background-image: url(~@/assets/img/coupon.png);
			background-size: 100% 100%;
		}

		.coupon-content {
			max-height: 80px;
			overflow-x: auto;
		}

		.cycle-text {
			color: #5D6066;
			font-size: 14px;
			margin-top: 10px;
			width: 330px;
			/* 隐藏超出部分的内容 */
			overflow: hidden;
			/* 禁止文本换行 */
			white-space: nowrap;
			/* 在文本末尾添加省略号 */
			text-overflow: ellipsis;
		}

		/* 隐藏垂直滚动条 */
		.coupon-content::-webkit-scrollbar {
			width: 0;
		}

		/* 隐藏水平滚动条 */
		.coupon-content::-webkit-scrollbar {
			height: 0;
		}
	}
</style>