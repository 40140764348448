<!-- 订单管理-会员月卡 -->

<template>
  <div class="order-prestore main-cnt">
    <div class="content">
      <common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :extraParame="{req_type:'5'}"
        :apiName="Order.getChargingOrderLists" :filters="filters" :columns="tableColumns" @throwTotal="throwTotal"
        @onDetails="onDetails">
        <template #continuity="{ row }">
          <div class="flex align-center">
            <div class="dots" :class="row.is_continuity_text == '是' ? 'bg-green' : 'bg-orange'"></div>
            <div>{{ row.is_continuity_text }}</div>
          </div>
        </template>

        <template #amount>
          <div class="flex amount">
            <div>
              <span class="amount-title f-w-bold">合计</span>
              <span class="order-total">{{ all_money }}</span>
            </div>
          </div>
        </template>
      </common-table>
    </div>

    <!-- 详情 -->
    <memberMonthCardDetails ref="infoRef"></memberMonthCardDetails>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch, } from "vue";
  import { Order, } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import { ElMessage, } from "element-plus";
  import memberMonthCardDetails from "../components/memberMonthCardDetails.vue";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  // 监听权限
  watch(() => menuTokens.value, (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
    {
      deep: true,
      immediate: true,
    }
  )
  /** 表格对象 */
  const roleTable = ref(null);
  const all_money = ref(0);  // 合计
  /** 筛选条件列表 */
  const filters = ref([
    {
      name: "keywords",
      filterType: "search",
      value: "",
      placeholder: "请输入用户手机号",
    },
    {
      filterType: "date",
      name: "start_time",
      name2: "end_time",
      value: [],
      startText: "收款开始时间",
      endText: "收款结束时间",
      placeholder: "请选择日期范围",
      type: "daterange",
    },
    {
      filterType: "select",
      name: "mc_id",
      value: "",
      placeholder: "请选择月卡名称",
      options: [],
      val: "mc_id",
      lab: "mc_name",
    },
    {
      filterType: "select",
      name: "is_continuity",
      value: "",
      placeholder: "请选择是否连续包月",
      options: [{ id: '1', name: '否' }, { id: '2', name: '是' }],
      val: "id",
      lab: "name",
    },
  ]);

  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "om_no",
      label: "订单编号",
      color: "--text-color",
      showTooltip: true,
      minWidth: 120,
    },
    {
      prop: "om_money",
      label: "订单金额",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "m_mobile",
      label: "绑定用户",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "pay_time",
      label: "支付时间",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "om_mc_name",
      label: "月卡名称",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "om_month",
      label: "购买月份",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "continuity",
      type: 'customRender',
      label: "是否连续包月",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "useRange",
      label: "有效期",
      color: "--text-color",
      minWidth: 100,
      showTooltip: true,
    },
    {
      type: "operation",
      prop: "",
      label: "附件",
      minWidth: 100,
      bottons: [
        {
          name: "详情",
          action: "onDetails",
          className: "theme-font-btn",
          token: "n_4mi71DqUuROesG1IyvrP0TEt5ILw",
        },
      ],
    },
  ]);
  const infoRef = ref(null);  // 详情对象
  /**
   * 
   * 获取月卡选项
   * 
   * **/
  const getMonthlyCardOption = () => {
    Order.getMonthlyCardOption().then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 订单金额合计
   * 
   * **/
  const throwTotal = (datas) => {
    all_money.value = datas.all_money;
  }
  /**
   * 
   * 详情按钮
   * 
   * **/
  const onDetails = (row) => {
    infoRef.value.openDialog(row);
  }

  onMounted(() => {
    getMonthlyCardOption();
    roleTable.value.tableLoad();
  });
</script>

<style lang="scss" scoped>
  .order-prestore {
    font-family: "Source Han Sans CN";

    .amount {
      margin-top: 10px;
      justify-content: space-between;
    }

    .amount-title {
      font-size: 14px;
    }

    .order-total {
      margin-left: 230px;
      color: #1AC994;
    }

    .content {
      padding: 20px;
    }
  }
</style>